<template>
    <LoadingWrapper
        :isLoading="!isReady"
        class="h-full w-full"
    >
        <div class="flex flex-row-reverse items-center h-full">
            <label
                v-if="false && !!rendererLabel"
                :for="rendererId"
                >{{ rendererLabel }}</label
            >
            <div class="flex-grow h-full text-xs items-center max-h-8">
                <select
                    class="flex-grow w-full h-8 rounded-lg text-xs text-gray-700 placeholder-gray-400 items-center disabled:cursor-not-allowed"
                    :name="rendererId"
                    :id="rendererId"
                    @change="onSelectionChanged"
                    :disabled="isUploaded || isUploading || !isReady"
                >
                    <option
                        v-for="option in options"
                        :key="`select-option-${option.id}`"
                        :value="option.id"
                        :disabled="option.disabled()"
                        :selected="option.selected()"
                    >
                        {{ option.label }}
                    </option>
                    <!-- </FormKit> -->
                </select>
            </div>
            <div
                class="inline-flex flex-shrink-0 items-center"
                :id="`${rendererId}-status`"
            >
                <component
                    :is="getStatusIcon()"
                    class="h-6 w-6 mr-1"
                    :class="getStatusColor()"
                />
            </div>
        </div>
    </LoadingWrapper>
</template>

<script>
    // <!-- API -->
    import { defineComponent, toRefs, ref, computed } from 'vue';
    import isNil from 'lodash-es/isNil';

    // <!-- COMPOSABLES -->
    /** @template [S=any] @typedef {import('@/features/csv-uploader/hooks/input/useSelectOption').OptionState<S>} OptionState<S> */
    /** @typedef {import('@/features/csv-uploader/hooks/input/useSelectOption').LocationOptionState} LocationOptionState */

    // <!-- COMPONENTS -->
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import {
        StarIcon,
        CheckCircleIcon,
        XCircleIcon,
    } from '@heroicons/vue/solid';

    /** <!-- TYPES --> */
    /** @typedef {import('@/models/locations/Location').LocationResource} LocationResource */

    /**
     * @typedef {Object} ISelectLocationParams
     * @property {Pick<LocationResource, 'path'>} value Actual value being passed to this cell.
     * @property {{ index: Number, name: String, type: String, size: Number, suggestion?: LocationResource, location?: LocationResource, path?: String, uploading?: Boolean, uploaded?: Boolean }} data Row data for this cell.
     * @property {String} label Renderer lable.
     * @property {Map<Number, LocationResource>} locationIndex Readonly location index for all cells.
     * @property {(event: LocationOptionState['value']) => Promise<void>} onOptionSelected Callback invoked when the selection is updated.
     */

    /**
     * @typedef {ISelectLocationParams & Omit<AgGrid.ICellRendererParams, 'value' | 'data'>} SelectLocationParams
     */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'SelectLocationCellRenderer',
        components: {
            LoadingWrapper,
            StarIcon,
            CheckCircleIcon,
            XCircleIcon,
        },
        props: {
            params: {
                /** @type {V.PropType<SelectLocationParams>} */
                // ts-ignore
                type: Object,
                required: true,
            },
        },
        setup(props, context) {
            // ==== PROPS ====

            /** @type {{ params: V.Ref<SelectLocationParams> }} */
            const { params } = toRefs(props);

            // ==== COMPOSABLES ====

            // Modify these configuration settings here in order to change behavior for all location cells.
            const useConfig = () => {
                const $node = params.value.node;
                const $data = params.value.data;
                return Object.freeze({
                    /** @type {Readonly<String>} Unique identifier for this renderer. */
                    rendererId: `select-location-${$node?.id}`,
                    /** @type {Readonly<String>} Initial `<select>` element label value. */
                    rendererLabel: params.value?.label,
                    /** @type {Readonly<String>} Filename associated with this cell. The record id. */
                    filename: $data?.name,
                    /** @type {Readonly<LocationResource>} Initial location selected by the record. `null` if no selection has been made. */
                    location: $data?.location ?? null,
                    /** @type {Readonly<LocationResource>} Location suggested by the server. `null` if no suggestion has been made. */
                    suggestion: $data?.suggestion ?? null,
                    /** @type {Readonly<Boolean>} If `true`, sort options in ascending natural order. */
                    ascending: true,
                    /** Defined placeholder option to insert at start of options array. */
                    placeholder: useLocationOption(
                        $data?.name,
                        {
                            id: -1,
                        },
                        {
                            label: 'Select Location',
                            disabled: false,
                        }
                    ),
                    /** @type {Readonly<Boolean>} Is the record uploading? */
                    uploading: $data?.uploading,
                    /** @type {Readonly<Boolean>} Is the record uploaded? */
                    uploaded: $data?.uploaded,
                });
            };

            // Use this internal composable to generate the initial state for the cell renderer.
            const useState = () => ({
                /** @type {V.Ref<String>} Current renderer id. */
                rendererId: ref(config.rendererId),
                /** @type {V.Ref<String>} Current `<select>` element label. */
                rendererLabel: ref(config.rendererLabel),
                /** @type {V.Ref<Number>} Selected option id. */
                selected: ref(config.location?.id ?? -1),
                /** @type {V.Ref<Boolean>} When true, cell is considered ready to display. */
                initialized: ref(false),
            });

            // Use this internal composable to generate computed properties for the cell renderer.
            const useComputed = () => {
                /**
                 * Location index with plucked data (and reasonable defaults).
                 * @type {V.ComputedRef<Map<Number, Pick<LocationResource, 'id' | 'name' | 'path' | 'label' | 'hierarchy' | 'hierarchyId' | 'timezone'>>>}
                 */
                const locationDetails = computed(() => {
                    /** @type {Map<Number, LocationResource>} */
                    const index = params.value.locationIndex;
                    const entries = [...index];
                    return new Map(
                        entries.map(([id, location]) => {
                            const {
                                name,
                                path,
                                label,
                                timezone,
                                hierarchy,
                                hierarchyId,
                            } = location;
                            const scoped = {
                                id,
                                name: name ?? '<Missing Name>',
                                path: path ?? '<Missing Hierarchy>',
                                label: label ?? '<Missing Location>',
                                hierarchy,
                                hierarchyId,
                                timezone,
                            };
                            /** @type {[ id: Number, location: scoped ]} */
                            const entry = [id, scoped];
                            return entry;
                        })
                    );
                });

                /** @type {V.ComputedRef<Map<LocationOptionState['id'], LocationOptionState>>} Dictionary of Location Options. */
                const dictionary = computed(() => {
                    const index = params.value.locationIndex;
                    return useOptionsDictionary(index);
                });

                /** @type {V.ComputedRef<LocationOptionState[]>} Sorted options for the HTML template. */
                const options = computed(() => {
                    // Get reference to the placeholder option so we can inject it at the start.
                    const placeholder = dictionary.value.get(-1);

                    // Get reference to the option entries as defined in the dictionary.
                    const entries = Array.from(
                        dictionary.value,
                        ([_, option]) => option
                    );

                    /** Filter out all options that do not correspond to a valid location. */
                    const options = entries.filter(
                        ({ id }) =>
                            !isNil(id) &&
                            id !== -1 &&
                            locationDetails.value.has(id)
                    );

                    /** Sort filtered, valid options according to the configuration setting. */
                    const { ascending = true } = config;
                    const sorted = options.sort((a, b) => {
                        const comparison = a.label.localeCompare(b.label);
                        return ascending ? comparison : -comparison;
                    });

                    /** Returns a sorted.unshift(placeholder) equivalent. Injects default selection to first location. */
                    return [placeholder, ...sorted];
                });

                /** @type {V.ComputedRef<Boolean>} Is there a selected option? */
                const hasSelectedOption = computed(() => {
                    const id = state.selected.value;
                    return !isNil(id) && id !== -1;
                });

                /** @type {V.ComputedRef<Boolean>} Is there a valid selection? */
                const hasValidSelectedOption = computed(() => {
                    const id = state.selected.value;
                    return !isNil(id) && id !== -1 && getters.isOptionValid(id);
                });

                /** @type {V.ComputedRef<LocationResource>} Current selected location */
                const inputLocation = computed(() => {
                    const { location } = config;
                    return location;
                });

                /** @type {V.ComputedRef<LocationResource>} Current suggested location */
                const suggestedLocation = computed(() => {
                    const { suggestion } = config;
                    return suggestion;
                });

                /** @type {V.ComputedRef<Pick<LocationResource, 'id' | 'name' | 'path'>>} Current selected location */
                const selectedLocation = computed(() => {
                    return hasValidSelectedOption.value === true
                        ? locationDetails.value.get(state.selected.value)
                        : null;
                });

                /** @type {V.ComputedRef<Boolean>} Is the cell ready to display options? */
                const isReady = computed(
                    () => state.initialized.value === true
                );

                /** @type {V.ComputedRef<Boolean>} */
                const isUploading = computed(() => config.uploading);

                /** @type {V.ComputedRef<Boolean>} */
                const isUploaded = computed(() => config.uploaded);

                // EXPOSE
                return {
                    isReady,
                    locationDetails,
                    dictionary,
                    options,
                    inputLocation,
                    suggestedLocation,
                    selectedLocation,
                    hasSelectedOption,
                    hasValidSelectedOption,
                    isUploading,
                    isUploaded,
                };
            };

            // Use this internal composable to generate getters.
            const useGetters = () => {
                /** @type {(id: Number) => Boolean} Checks if id is equivalent to the selected value. */
                const isOptionSelected = (id) => {
                    // If option id is found, compare it against the `selected` option.
                    return (
                        properties.hasSelectedOption.value &&
                        state.selected.value === id
                    );
                };

                /** @type {(id: Number) => Boolean} Checks if id is a valid selection. */
                const isOptionValid = (id) => {
                    // If option id is found and it is not the placeholder id, it is valid.
                    return (
                        !isNil(id) &&
                        !!properties.dictionary.value &&
                        properties.dictionary.value.has(id)
                    );
                };

                /** @type {(id: Number) => Boolean} Checks if id is the placeholder selection. */
                const isPlaceholderOption = (id) => {
                    const { placeholder } = config;
                    return isNil(id) || placeholder.id === id;
                };

                /** @type {(id: Number) => Boolean} Checks if id matches that of the suggested location. */
                const isSuggestedOption = (id) => {
                    // If the suggested location id matches the option id, we are on the suggested option.
                    const suggestion = properties.suggestedLocation.value;
                    return !!suggestion && suggestion.id === id;
                };

                /** @type {() => String} Checks if id matches that of the suggested location. */
                const getStatusColor = () => {
                    const id = state.selected.value;
                    if (isOptionValid(id) && !isPlaceholderOption(id)) {
                        const { isUploading, isUploaded } = properties;
                        if (isUploaded.value) {
                            return `text-primary-500`;
                        }
                        // If the selected option matches the suggested location, return a 'secondary' color.
                        // If the selected option is valid, but is the user's choice, return a green.
                        // return isSuggestedOption(id) && !isUploading.value
                        //     ? 'text-secondary-500'
                        //     : 'text-green-500';
                        // Note: Client no longer wants suggestion color.
                        return 'text-green-500';
                    }
                    // If the selected option is invalid, return danger.
                    return 'text-red-500';
                };

                /** Checks if id matches that of the suggested location. */
                const getStatusIcon = () => {
                    const id = state.selected.value;
                    if (isOptionValid(id) && !isPlaceholderOption(id)) {
                        const { isUploading, isUploaded } = properties;
                        // If the selected option matches the suggested location, return a 'star'.
                        // If the selected option is valid, but is the user's choice, return a 'check mark'.
                        // return isSuggestedOption(id) &&
                        //     !isUploading.value &&
                        //     !isUploaded.value
                        //     ? StarIcon
                        //     : CheckCircleIcon;
                        // Note: Client no longer wants suggested location icon.
                        return CheckCircleIcon;
                    }
                    // If the selected option is invalid, return an 'x'.
                    return XCircleIcon;
                };

                // EXPOSE
                return {
                    isOptionSelected,
                    isOptionValid,
                    isPlaceholderOption,
                    isSuggestedOption,
                    getStatusColor,
                    getStatusIcon,
                };
            };

            /**
             * Create the options dictionary from the provided location index.
             * @param {Map<Number, LocationResource>} index
             * @returns {Map<LocationOptionState['id'], LocationOptionState>}
             */
            const useOptionsDictionary = (index) => {
                // Get configuration settings.
                const { filename, placeholder } = config;

                // Prepare the options dictionary.
                const options = {
                    /** @type {Map<Number, LocationOptionState>} */
                    dictionary: new Map(),
                };

                // Insert placeholder option into the new dictionary.
                options.dictionary = options.dictionary.set(
                    placeholder.id,
                    placeholder
                );

                // For every location present in the passed location index, create an option for it.
                index.forEach((location, id) => {
                    const option = useLocationOption(filename, location);
                    options.dictionary = options.dictionary.set(id, option);
                });

                // Return the options dictionary.
                return options.dictionary;
            };

            /**
             * Determine current component selection when mounting based on the:
             * - input selected value (if store has one already),
             * - the suggested value (if suggestion exists),
             * - the placeholder id, when no other value is contributed.
             * @param {Number} [input] Initial config selected value.
             * @param {Number} [suggested] Location id for the suggested location, if one is provided.
             * @param {Number} [placeholder] Assumes -1 when not provided.
             */
            const useInitialSelection = (
                input = -1,
                suggested = -1,
                placeholder = -1
            ) => {
                const $locationIndex = params.value.locationIndex;
                if (!isNil(input) && $locationIndex.has(input)) {
                    // Select this option.
                    actions.onSelectionChanged({ target: { value: input } });
                    return input;
                }
                if (!isNil(suggested) && $locationIndex.has(suggested)) {
                    // Select this option.
                    actions.onSelectionChanged({
                        target: { value: suggested },
                    });
                    return suggested;
                }
                return placeholder ?? -1;
            };

            // Use this internal composable to generate actions.
            const useActions = () => {
                /**
                 * Event handler for when the component is initialized.
                 */
                const onInitialized = async () => {
                    // Get configuration settings.
                    const { placeholder } = config;

                    // Prepare the deserialized input location id.
                    const input = {
                        id: properties.inputLocation.value?.id ?? -1,
                    };

                    // Prepare the suggested location id.
                    const suggested = {
                        id: properties.suggestedLocation.value?.id ?? -1,
                    };

                    // Prepare initial selected option.
                    const initial = {
                        selected: useInitialSelection(
                            input.id,
                            suggested.id,
                            placeholder.id
                        ),
                    };

                    // Set the selected state.
                    state.selected.value = initial.selected;

                    // Make initialized.
                    state.initialized.value = true;
                };

                /**
                 * Event handler for option changed.
                 * @param {{ target: { value: String | Number }}} event Event payload.
                 */
                const onSelectionChanged = (event) => {
                    /** @type {{ id: Number }} Parse the event target. */
                    const option = {
                        id:
                            typeof event.target.value === 'string'
                                ? parseInt(event.target.value, 10)
                                : event.target.value,
                    };

                    // Validate option.
                    const valid =
                        !isNaN(option.id) &&
                        properties.dictionary.value.has(option.id);
                    state.selected.value = valid ? option.id : -1;

                    // Get corresponding option value.
                    const response = properties.dictionary.value.get(
                        option.id
                    )?.value;

                    // If placeholder option, deselect location.
                    if (
                        isNil(response.location) ||
                        getters.isPlaceholderOption(response.location.id)
                    ) {
                        console.warn(`Deselecting location.`);
                        response.location = null;
                    }

                    // Invoke the selected option callback.
                    params.value?.onOptionSelected(response);
                };

                // EXPOSE
                return {
                    onInitialized,
                    onSelectionChanged,
                };
            };

            /**
             * Map a location resource entry into a valid option structure.
             * @param {String} filename Record filename.
             * @param {Pick<LocationResource, 'id'> & Partial<Pick<LocationResource, 'name' | 'path' | 'label'>>} details Location details to create option state from.
             * @param {Object} [attributes] Additional configuration settings.
             * @param {String} [attributes.label] Optional label that overrides the stored details.
             * @param {Boolean} [attributes.disabled] Optional parameter determining if this option is forcibly disabled.
             */
            const useLocationOption = (filename, details, attributes) => {
                // Extract with default values when undefined.
                const {
                    id = -1,
                    name = null,
                    path = null,
                    label = null,
                } = details ?? {};
                const { disabled = false } = attributes ?? {};

                // Computed properties for dynamic state.
                const optionLabel =
                    label ??
                    attributes?.label ??
                    [
                        !path || path.length <= 0
                            ? `<Missing Hierarchy>`
                            : path,
                        !name || name.length <= 0 ? `<Missing Name>` : name,
                    ].join('/');
                const isOptionSelected = () => {
                    const selected = getters.isOptionSelected(id);
                    return selected;
                };
                const isOptionDisabled = () => {
                    const condition = disabled || !getters.isOptionValid(id);
                    return condition;
                };

                // Create the stored option value.
                /** @type {{ filename: String, location: Pick<LocationResource, 'id' | 'name' | 'path'> & { label: String } }} */
                const optionValue = {
                    /** @type {String} */
                    filename,
                    /** @type {Pick<LocationResource, 'id' | 'name' | 'path'> & { label: String }} */
                    location: {
                        id,
                        name: !name || name.length <= 0 ? null : name,
                        path: !path || path.length <= 0 ? null : path,
                        label: optionLabel,
                    },
                };

                // EXPOSE
                return {
                    id,
                    selected: isOptionSelected,
                    disabled: isOptionDisabled,
                    label: optionLabel,
                    value: optionValue,
                };
            };

            // ==== SETTINGS ====

            // Load configuration.
            const config = useConfig();

            // ==== STATE ====

            // Assign state.
            const state = useState();

            // ==== COMPUTED PROPERTIES ====

            // Assign properties.
            const properties = useComputed();

            // ==== GETTERS ====

            // Assign getters.
            const getters = useGetters();

            // ==== ACTIONS ====

            // Assign actions.
            const actions = useActions();

            // ==== LIFECYCLE ====

            // Prepare the select options.
            actions.onInitialized();

            return {
                ...state,
                ...properties,
                ...getters,
                ...actions,
            };
        },
    });
</script>
