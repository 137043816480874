// <!-- API -->
import { ref, computed } from 'vue';
import isNil from 'lodash-es/isNil';

// <!-- COMPOSABLES -->
import useAgGridVue from '@/hooks/useAgGridVue';

// <!-- COMPONENTS -->
import SelectLocationCellRenderer from '~CSVUploader/components/cells/SelectLocationCellRenderer.vue';

// <!-- TYPES -->
import { Store } from 'vuex';
import { UploadRecord } from '@/store/types/uploader/state';
/** @typedef {import('@/models/locations/Location').LocationResource} LocationResource */

/**
 * Get the location selection grid.
 *
 * @param {V.SetupContext<any>} context Setup context.
 * @param {Store} store
 * @param {Object} payload
 * @param {V.Ref<Map<String, UploadRecord>>} payload.records
 * @param {V.Ref<Map<Number, LocationResource>>} payload.locationIndex
 * @param {Object} api
 * @param {(event: { filename: String, id: Number }) => Promise<UploadRecord>} api.onLocationSelected
 * @param {(event: { filename: String }) => Promise<UploadRecord>} api.onLocationDeselected
 */
export function useLocationSelectGrid(
    { emit },
    // ts-ignore
    { commit },
    { records, locationIndex },
    { onLocationSelected, onLocationDeselected }
) {
    /** Get grid API. */

    const { ...grid } = useAgGridVue();

    const defaultColDef = Object.freeze({
        resizable: true,
        sortable: true,
        filter: true,
        floatingFilter: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        suppressMovable: true,
        suppressMenu: true,
        lockPosition: true,
        minWidth: 150,
        flex: 1,
        cellClass: 'flex justify-center items-center',
    });

    /**
     * Selected location row data.
     * @type {V.ComputedRef<({ name: String, type: String, size: Number,
     *                       location?: LocationResource, path?: String,
     *                       suggestion?: LocationResource,
     *                       uploading?: Boolean, uploaded?: Boolean })[]>}
     */
    const selectedRecords = computed(() => {
        return [...records.value.values()].map((r) => {
            const { filename, file, location, suggestedLocation } = r;
            const selected = r.isLocationSelected ? location.value : null;
            return {
                name: filename,
                type: file.exists ? file.value.type : '',
                size: file.exists ? file.value.size : 0,
                location: selected,
                path: r.isLocationSelected
                    ? location.value.path
                    : '<Missing Hierarchy>',
                suggestion: r.isLocationSuggested ? suggestedLocation : null,
                uploading: r.isUploadingDataset,
                uploaded: r.isDatasetBatchUploaded,
            };
        });
    });

    /** Updating flag. */
    const status = ref(new Set());

    /** Computed property. */
    const isUpdatingSelection = computed(() => {
        const _ = status.value;
        return _.has('updating');
    });

    /** References. */
    const state = {
        defaultColDef: ref(defaultColDef),
        domLayout: ref('autoHeight'),
        /** @type {V.Ref<AgGrid.ColumnDef[]>} */
        columnDefs: ref([]),
        rowSelection: ref('multiple'),
        rowData: computed(() =>
            actions.mapRecordsToRowData(selectedRecords.value)
        ),
        /** @type {V.Ref<GridApi>} */
        gridApi: ref(null),
        /** @type {V.Ref<ColumnApi>} */
        gridColumnApi: ref(null),
        rowHeight: ref(50),
    };

    // HOOKS
    const useDomLayout = (layout) => {
        state.domLayout.value = layout;
    };

    const useDefaultColDef = (defaultDefs) => {
        state.defaultColDef.value = defaultDefs;
    };

    const useColumnDefs = (colDefs) => {
        state.columnDefs.value = colDefs;
    };

    const useGridApi = (api) => {
        state.gridApi.value = api;
    };

    const useGridColumnApi = (api) => {
        state.gridColumnApi.value = api;
    };

    const useRowHeight = (height) => {
        state.rowHeight.value = height;
    };

    const getFileType = (params) => {
        console.dir(params);
        if (
            params?.data?.type?.length === 0 &&
            params?.data?.name?.length > 0 &&
            params?.data?.name?.includes('.')
        ) {
            // Could be a PEM file type.
            const name = params?.data?.name;
            const PEM = /^P_A[\d]{1,5}[.][\d]{1,4}$/i;
            const PEM2 = /^P2_[\d]{1,5}[.][\d]{1,4}$/i;
            const isPEM2File = PEM2.test(name);
            const isPEM1File = PEM.test(name);
            if (isPEM1File) {
                return 'bin/PEM';
            }
            if (isPEM2File) {
                return 'text/PEM';
            }
        }
        // If not PEM, return normal file type.
        return params?.data?.type;
    };

    // GETTERS
    const getters = {
        // Allows us to reorganize easily.
        /** @returns {Record<String, AgGrid.ColumnDef>} */
        getAssignLocationColumnDefs: () => ({
            index: {
                headerName: '',
                field: 'index',
                width: 35,
                pinned: true,
                sortable: true,
                resizable: false,
                cellClass: 'flex justify-center items-center',
            },
            location: {
                headerName: 'Location (Selected)',
                field: 'location',
                width: 100,
                minWidth: 80,
                sortable: true,
                resizable: true,
                suppressMenu: true,
            },
            suggestion: {
                headerName: 'Suggested Location',
                field: 'suggestion',
                width: 100,
                minWidth: 80,
                sortable: true,
                resizable: true,
                suppressMenu: true,
            },
            path: {
                headerName: 'Location',
                field: 'path',
                width: 350,
                minWidth: 150,
                sortable: true,
                resizable: false,
                suppressMenu: true,
                suppressSizeToFit: false,
                cellClass: 'h-full',
                cellRendererFramework: SelectLocationCellRenderer,
                cellRendererParams: {
                    label: 'Location',
                    locationIndex,
                    onOptionSelected: actions.onOptionSelected,
                },
            },
            label: {
                headerName: 'Location',
                field: 'label',
                width: 350,
                minWidth: 150,
                sortable: true,
                resizable: false,
                suppressMenu: true,
                suppressSizeToFit: false,
                cellClass: 'h-full',
                cellRendererFramework: SelectLocationCellRenderer,
                cellRendererParams: {
                    label: 'Location',
                    locationIndex,
                    onOptionSelected: actions.onOptionSelected,
                },
            },
            name: {
                headerName: 'File Name',
                field: 'name',
                minWidth: 250,
                width: 250,
                cellClass: 'flex text-left items-center',
                pinned: true,
                resizable: true,
                sortable: true,
                suppressMenu: true,
                suppressSizeToFit: true,
                suppressAutoSize: true,
            },
            type: {
                headerName: 'File Type',
                field: 'type',
                minWidth: 80,
                valueGetter: getFileType,
                resizable: true,
                sortable: true,
                suppressMenu: true,
            },
            size: {
                headerName: 'File Size',
                field: 'size',
                minWidth: 80,
                resizable: true,
                sortable: true,
                suppressMenu: true,
            },
            uploading: {
                headerName: 'Uploading?',
                field: 'uploading',
                minWidth: 80,
                resizable: true,
                sortable: true,
                suppressMenu: true,
            },
            uploaded: {
                headerName: 'Uploaded?',
                field: 'uploaded',
                minWidth: 80,
                resizable: true,
                sortable: true,
                suppressMenu: true,
            },
        }),
        getRowIdFromFileName: (data) => data.name,
    };

    // ACTIONS
    const actions = {
        /**
         * @param {selectedRecords['value']} records
         */
        mapRecordsToRowData: (records) => {
            return records.map((record, index) => ({
                index,
                ...record,
            }));
        },
        updateColumnDefs: () => {
            const columnDefs = getters.getAssignLocationColumnDefs();
            useColumnDefs([columnDefs.name, columnDefs.label]);
        },
        onGridReady: (event) => {
            state.gridApi.value = event.api;
            state.gridColumnApi.value = event.columnApi;
        },
        onColumnResized: (event) => {
            // See: grid.onColumnResized(event);
            state.gridApi.value?.refreshCells();
            emit('resize:grid', event); // Currently unused.
        },
        // ts-ignore
        onRowDataChanged: (event) => {
            state.gridApi.value?.sizeColumnsToFit();
            state.gridApi.value?.refreshCells();
            state.gridColumnApi.value?.autoSizeAllColumns(false);
        },
        // ts-ignore
        onChangeRowData: (event) => {
            state.gridApi.value?.refreshCells();
        },
        /**
         * On selection of an option, appropriately update record.
         * @param {Object} event Event object.
         * @param {String} event.filename Affected record filename.
         * @param {Pick<LocationResource, 'id' | 'name' | 'path'> & { label: String } | null} [event.location] Location option value, if present.
         * @returns {Promise<UploadRecord>} Promise gives affected record when resolved.
         */
        onOptionSelected: async (event) => {
            const { filename = null, location = null } = event ?? {};
            try {
                status.value = new Set(status.value.add('updating'));
                if (isNil(event) || isNil(filename)) {
                    throw new TypeError(`Missing required event parameters.`);
                }
                if (
                    isNil(location) ||
                    isNil(location?.id) ||
                    location?.id === -1
                ) {
                    // Remove location if one is deselected.
                    return await onLocationDeselected({ filename });
                }
                if (typeof location?.id === 'number' && location?.id !== -1) {
                    // Add location if one is selected.
                    return await onLocationSelected({
                        filename,
                        id: location?.id,
                    });
                }
                // No changes.
                console.warn(`No records updated.`);
                return null;
            } catch (err) {
                console.error(err);
            } finally {
                status.value.delete('updating');
                status.value = new Set(status.value);
            }
        },
    };

    return {
        useGridApi,
        useGridColumnApi,
        useDefaultColDef,
        useColumnDefs,
        useDomLayout,
        useRowHeight,
        state,
        getters,
        actions,
        isUpdatingSelection,
    };
}

export default useLocationSelectGrid;
