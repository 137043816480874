// <!-- API -->
import { ref } from 'vue';
import { useStore } from 'vuex';
import isNil from 'lodash-es/isNil';

// <!-- TYPES -->
// ts-ignore

import { Store } from 'vuex';
import { ECNBState } from '@/store/types/ECNBStore';
import { UploadRecord } from '@/store/types/uploader/state';
/** @typedef {import('@/models/locations/Location').LocationResource} LocationResource */

/**
 * Using a store (or with a new instance), handle request operations.
 * @param {Store<ECNBState>} [vuexStore]
 */
export const useLocationSuggestor = (vuexStore) => {
    // STORE

    /** @type {Store<ECNBState>} */
    const store = vuexStore ?? useStore();

    // STATE

    /** @type {V.Ref<Boolean>} */
    const isFetchingSuggestions = ref(false);

    /** @type {V.Ref<Set<String>>} */
    const filenames = ref(new Set());

    /** @type {V.Ref<Map<String, LocationResource>>} */
    const suggestions = ref(new Map());

    // METHODS

    /**
     * Get the current cached suggestions.
     * @param {Map<String, UploadRecord>} records
     */
    const updateCachedSuggestions = (records) => {
        // Clear cached suggestions.
        filenames.value = new Set();
        suggestions.value = new Map();
        [...records.values()].forEach((record) => {
            const filename = record.file.value.name;
            const suggestion = record.suggestedLocation ?? null;
            filenames.value = filenames.value.add(filename);
            if (!isNil(suggestion)) {
                suggestions.value = suggestions.value.set(filename, suggestion);
            }
        });
    };

    /** @param {String} name */
    const isFilenameMissing = (name) =>
        filenames.value.size === 0 || !filenames.value.has(name);

    /** @param {String} name */
    const isSuggestionMissing = (name) =>
        suggestions.value.size === 0 ||
        !suggestions.value.has(name) ||
        isNil(suggestions.value.get(name));

    /** @param {String[]} names */
    const isAnyFilenameMissing = (names) => {
        return names.some(isFilenameMissing);
    };

    /** @param {String[]} names */
    const isAnySuggestionMissing = (names) => {
        return names.some(isSuggestionMissing);
    };

    /**
     * Refetch the cached suggested locations for the page. Does not store the suggestions.
     * @param {Map<String, UploadRecord>} records Records we are suggesting locations for.
     * @param {Boolean} forceReload Clear cached suggestions, if true.
     * @returns {Promise<Map<String, LocationResource>>} Return suggestions.
     */
    const refreshLocationSuggestions = async (records, forceReload = false) => {
        try {
            isFetchingSuggestions.value = true;
            const ignoreCache = forceReload === true;
            const all = [...records.values()];
            const storedFilenames = all.map((r) => r.file.value.name);
            updateCachedSuggestions(records);
            if (
                ignoreCache ||
                isAnyFilenameMissing(storedFilenames) ||
                isAnySuggestionMissing(storedFilenames)
            ) {
                // Request new suggestions if:
                // - Cache is ignored,
                // - One or more locations is missing from cache.
                // - One or more filenames are missing a suggestion.
                // Request the suggested locations.
                const { dispatch } = store;
                suggestions.value = await dispatch(
                    `uploader/data/suggestRecordLocations`
                );
                if (isNil(suggestions.value) || suggestions.value.size === 0) {
                    console.warn(`No suggestions found!`);
                }
            }
            // Return the cached (or just fetched) suggestions.
            return suggestions.value;
        } catch (err) {
            console.error(err);
        } finally {
            isFetchingSuggestions.value = false;
        }
    };

    return {
        refreshLocationSuggestions,
        isFetchingSuggestions,
        store,
        suggestions,
    };
};
